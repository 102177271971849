.paper {
  overflow-y: auto;
}

.text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inline {
  display: inline;
}
