.sideBar {
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
  width: 100%;
  background-color: #f5f3f0 !important;

  @media screen and(min-width: 768px) {
    width: 25%;
  }
}

.hidden {
  display: none;
}

.showListButton {
  width: 100%;
  color: #766F66 !important;
  justify-content: flex-start !important;

  @media screen and(min-width: 768px) {
    display: none;
  }
}