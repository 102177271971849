.paper {
  padding: 4px 2px;
  display: flex;
}

.input {
  flex: 1;
  height: 46px;
  padding-left: 8px;
}
