html,
body {
  margin: 0;
  overflow: hidden;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.gm-style .gm-style-iw-a {
  position: absolute;
  width: 9999px;
  height: 0;
}

.gm-style .gm-style-iw-t {
  position: absolute;
  width: 100%;
}

.gm-style .gm-style-iw-t::after {
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 51%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, 0.4);
  content: '';
  height: 15px;
  left: 0;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 15px;
}

.gm-style .gm-style-iw-c {
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  background-color: white;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
}

.gm-style .gm-style-iw-d {
  box-sizing: border-box;
  overflow: auto;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar {
  width: 18px;
  height: 12px;
  -webkit-appearance: none;
}
